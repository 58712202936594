import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import Header from '../Includes/Header/Header'
import Live from '../Live/Live'
import './FutbolEnVivo.css'
import Loader from '../Includes/Loader/Loader';
import Moment from 'react-moment';


const FutbolEnVivo = () => {

    const [partidosLive, setPartidosLive] = useState("");

    useEffect(() => {

        async function GetPatidosLive() {
            await axios({
                method: "GET",
                url: "https://api.viperplay.net/categorias/react",
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                }
            })
                .then(res => {
                    setPartidosLive(res.data.data);
                    console.log(res.data.data);
                })

                .catch(err => {
                    //   console.log("error in request", err);
                });
        }
        GetPatidosLive();

    }, []);


    const [UrlVideo, setUrlVideo] = useState("");
    const [FormatoVideo, setFormatoVideo] = useState("");
    const [Slug, setSlug] = useState("");


    const abrirLive = (UrlVideo, FormatoVideo, Slug) => {
        setUrlVideo(UrlVideo);
        setFormatoVideo(FormatoVideo);
        setSlug(Slug);
        window.scrollTo(0, 0);
    }


    return (

        <>
            <Helmet>
                <title>Futbol en vivo</title>
            </Helmet>

            <Header />

            {partidosLive ?
                <div className='contenedor-futbol-en-vivo'>

                    {UrlVideo !== "" && FormatoVideo !== "" ?
                        <Live UrlVideo={UrlVideo} FormatoVideo={FormatoVideo} Slug={Slug} />
                        : ""}

                    <h1 className='titulo-futbol-en-vivo'>Futbol en vivo</h1>

                    <>
                        {partidosLive.map((partido, index) => (
                            <div key={index}>
                                {partido !== partidosLive[0] ?
                                    <>
                                        <h1 className='titulo_categoria-futbol-en-vivo'> {partido.nombre} ● <strong> {partido.titulo} </strong> <img src={partido.pais} alt='pais' /></h1>
                                        <span className='fecha__categoria-futbol-en-vivo'>
                                         
                                            <Moment format="YYYY/MM/DD" style={{  marginRight:'6px' }}>
                                                {partido.created_at}
                                            </Moment>
                                            -
                                            <Moment format='LT' style={{ fontWeight:'bold', marginLeft:'6px' }}>
                                                <strong>{partido.horario}</strong>
                                            </Moment>

                                        </span>
                                        <div className='categorias-futbol-en-vivo'>
                                            {partido.canales.map((canal, index) => (

                                                <div className='categoria_partido' id={index} onClick={() => abrirLive(canal.url_video, canal.id_formato_canal, canal.slug)}  >
                                                    <div className='partido_en_vivo'>
                                                        <i className="fas fa-video"></i>
                                                        <h4 className='titulo_partido_en_vivo'>{canal.titulo}</h4>
                                                        <div className="circle"></div>
                                                    </div>
                                                </div>

                                            ))}
                                        </div>
                                    </>
                                    : ""}
                            </div>
                        ))}
                    </>


                </div>
                : <Loader />}
        </>
    )
}

export default FutbolEnVivo
