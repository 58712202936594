
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Header from '../Includes/Header/Header'
import Loader from '../Includes/Loader/Loader';

const Inicio = () => {

    const [UltimasPeliculas, setUltimasPeliculas] = useState("");
    const [PeliculasEnEstreno, setPeliculasEnEstreno] = useState("");
    const [PeliculasMasVistas, setPeliculasMasVistas] = useState("");
    const [PeliculasMejorCalificadas, setPeliculasMejorCalificadas] = useState("");


    const [PeliculasPortada, setPeliculasPortada] = useState("");



    useEffect(() => {

        async function GetUltimasPeliculas() {
            await axios({
                method: "POST",
                url: "https://cvn2.iptvpremium.io/movies",
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',

                },
                data: {
                    "type": 0,
                },
            })
                .then(res => {
                    setUltimasPeliculas(res.data);
                })

                .catch(err => {
                    //   console.log("error in request", err);
                });
        }
        GetUltimasPeliculas();

        async function GetPeliculasEnEstreno() {
            await axios({
                method: "POST",
                url: "https://cvn2.iptvpremium.io/movies",
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',

                },
                data: {
                    "type": 1,
                },
            })
                .then(res => {
                    setPeliculasEnEstreno(res.data);
                })

                .catch(err => {
                    //   console.log("error in request", err);
                });
        }
        GetPeliculasEnEstreno();

        async function GetPeliculasMasVistas() {
            await axios({
                method: "POST",
                url: "https://cvn2.iptvpremium.io/movies",
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',

                },
                data: {
                    "type": 2,
                },
            })
                .then(res => {
                    setPeliculasMasVistas(res.data);

                })

                .catch(err => {
                    //   console.log("error in request", err);
                });
        }
        GetPeliculasMasVistas();

        async function GetPeliculasMejorCalificadas() {
            await axios({
                method: "POST",
                url: "https://cvn2.iptvpremium.io/movies",
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',

                },
                data: {
                    "type": 3,
                },
            })
                .then(res => {
                    setPeliculasMejorCalificadas(res.data);

                })

                .catch(err => {
                    //   console.log("error in request", err);
                });
        }

        GetPeliculasMejorCalificadas();

        async function PeliculasPortada() {
            await axios({
                method: "POST",
                url: "https://cvn2.iptvpremium.io/moviesportada",
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',

                }
            })
                .then(res => {
                    setPeliculasPortada(res.data);

                })

                .catch(err => {
                    //   console.log("error in request", err);
                });
        }
        PeliculasPortada();

    }, []);

    return (

        <>
            <Header />

            <div className="swiper" id={UltimasPeliculas === "" || PeliculasEnEstreno === "" || PeliculasMasVistas === "" || PeliculasMejorCalificadas === "" ? "ocultar" : ""}>

                <div className="swiper-wrapper">

                    {PeliculasPortada === "" ? "" : PeliculasPortada.map((peliculapostada, index) => (
                        <div className="swiper-slide" key={index}>
                            <div className="swiper-image" style={{ background: "linear-gradient(0deg,rgb(0, 0, 0),rgba(0, 0, 0, 0)), url('" + peliculapostada.poster + "')" }}>
                            </div>

                            <div className="content-wrapper">
                                <div className="content">

                                    <span className="etiqueta_type">{peliculapostada.tipo}</span>

                                    <h1>{peliculapostada.title}</h1>

                                    <p>{peliculapostada.sypnosis}</p>

                                    <a href={peliculapostada.tipo === "pelicula" ? "pelicula/" + peliculapostada.id : peliculapostada.id}>
                                        <button className="btn_ver_pelicula_slide"><i className="fas fa-play"></i> Ver ahora</button>
                                    </a>

                                </div>
                            </div>
                        </div>
                    ))}

                </div>
            </div>


            {UltimasPeliculas === "" || PeliculasEnEstreno === "" || PeliculasMasVistas === "" || PeliculasMejorCalificadas === "" ?
                <Loader />

                :

                <>

                    {/*Últimas películas agregadas*/}
                    <div className='contenedor_wrapper'>
                        <h1 className='titulo_wrapper'>Últimas películas agregadas</h1>

                        <div className="wrapper">
                            {UltimasPeliculas.map((UltimaPelicula, index) => (
                                <a href={"pelicula/" + UltimaPelicula.id} key={index}>
                                    <div className="item ultimas_peliculas_agregadas" >
                                        <img alt="" src={UltimaPelicula.poster} />

                                    </div>
                                </a>
                            ))}
                        </div>
                    </div>



                    {/* Películas de estreno*/}
                    <div className='contenedor_wrapper' >
                        <h1 className='titulo_wrapper'>Películas de estreno</h1>
                        <div className="wrapper" >
                            {PeliculasEnEstreno.map((PeliculaEnEstreno, index) => (
                                <a href={"pelicula/" + PeliculaEnEstreno.id} key={index}>

                                    <div className="item ultimas_peliculas_agregadas" >
                                        <img alt="" src={PeliculaEnEstreno.poster} />

                                    </div>
                                </a>
                            ))}
                        </div>
                    </div>


                    {/*Películas más vistas*/}
                    <div className='contenedor_wrapper'>
                        <h1 className='titulo_wrapper'>Películas más vistas</h1>
                        <div className="wrapper">
                            {PeliculasMasVistas.map((PeliculaMaVista, index) => (
                                <a href={"pelicula/" + PeliculaMaVista.id} key={index}>

                                    <div className="item ultimas_peliculas_agregadas" key={index}>
                                        <img alt="" src={PeliculaMaVista.poster} />

                                    </div>
                                </a>
                            ))}
                        </div>
                    </div>


                    {/*Películas mejor calificadas*/}
                    <div className='contenedor_wrapper'>
                        <h1 className='titulo_wrapper'>Películas mejor calificadas</h1>
                        <div className="wrapper">

                            {PeliculasMejorCalificadas.map((PeliculaMejorCalificada, index) => (

                                <a href={"pelicula/" + PeliculaMejorCalificada.id} key={index}>
                                    <div className="item ultimas_peliculas_agregadas">
                                        <img alt="" src={PeliculaMejorCalificada.poster} />

                                    </div>
                                </a>

                            ))}
                        </div>
                    </div>
                </>
            }

            <div id='utilizar_slider_peliculas_inicio'></div>

        </>
    )
}

export default Inicio
