import React, { useEffect, useState } from 'react'
import axios from 'axios'
import './Video.css'
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import Loader from '../Includes/Loader/Loader';

const Video = () => {

    const { id, nombre } = useParams();

    const [UrlVideo, setUrlVideo] = useState();

    useEffect(() => {

        async function UrlsVideo() {
            await axios({
                method: "POST",
                url: "https://cvn2.iptvpremium.io/linkdrive",
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                },
                data: {
                    "id": id + "/" + nombre
                },
            })
                .then(res => {
                    console.log(res.data);
                    setUrlVideo(res.data);

                })
                .catch(err => {

                });
        }
        UrlsVideo();

    }, [id, nombre]);

    return (
        <>

            {UrlVideo ?
                <>
                    <Helmet>

                    </Helmet>

                    <div className='contenedor_reproductor_video'>

                        <video controls crossorigin playsinline poster="">

                            {UrlVideo?.latino.length > 0 ?
                                <source src={UrlVideo?.latino[0].url} type="video/mp4" size="1080" />
                                : ""}

                            {UrlVideo?.espanol.length > 0 ?
                                <source src={UrlVideo?.espanol[0].url} type="video/mp4" size="720" />
                                : ""}

                            {UrlVideo?.sub.length > 0 ?
                                <source src={UrlVideo?.sub[0].url} type="video/mp4" size="576" />
                                : ""}

                        </video>

                    </div>


                </>
                : <Loader />
            }
            <div id='reproductor_video'></div>
        </>
    )
}

export default Video
