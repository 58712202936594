import axios from 'axios';
import { useState } from 'react';

export default function useToken() {

    const getToken = () => {
        const tokenString = sessionStorage.getItem('token');
        
        const userToken = JSON.parse(tokenString);

        return userToken?.token;
    };

    const [token, setToken] = useState(getToken());

    const saveToken = userToken => {
        sessionStorage.setItem('token', JSON.stringify(userToken));
        setToken(userToken.token);
    };

    if (token) {
        async function VerificarStatusToken() {
            await axios({
                method: "GET",
                url: "https://dev.iptvpremium.io/api/auth/detalles-user",
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': 'Bearer ' + token,
                }
            })
                .then(res => {
                    if (res.data.data.mambresia.status === 0) {
                        sessionStorage.removeItem('token');
                        window.location.href = "/";
                    }
                })
                .catch(err => {
                    sessionStorage.removeItem('token');
                    window.location.href = "/";
                });
        }
        VerificarStatusToken();
    }

    return {
        setToken: saveToken,
        token
    }
}