import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Login.css';
import axios from 'axios';


async function loginUser(credentials) {
    return fetch('https://dev.iptvpremium.io/api/auth/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json())
}

export default function Login({ setToken }) {
    const [name, setName] = useState();
    const [password, setPassword] = useState();
    const [error, setError] = useState(null);

    const handleSubmit = async e => {
        e.preventDefault();

        const repuesta = await loginUser({
            name,
            password
        });

        if (!repuesta.ok) {
            setError(repuesta.error);
        } else {

            async function VirificarStatusUser() {
                await axios({
                    method: "get",
                    url: "https://dev.iptvpremium.io/api/auth/detalles-user",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': 'Bearer ' + repuesta.data.access_token
                    }
                })
                    .then(response => {

                        if (response.data.data.usuario.membresia.status === 1) {
                            setToken({ "token": repuesta.data.access_token });
                            window.location.href = "/";
                        } else {
                            setError("Membresia vencida");
                        }
                    })

                    .catch(err => {
                        //   console.log("error in request", err);
                    });
            }
            VirificarStatusUser();

        }

    }

    return (
        <div className="login-wrapper">

            <form onSubmit={handleSubmit} method="post" className="formulario_login">


                {error ? (
                    <div className="errores_auth">
                        <i className="fas fa-exclamation-triangle"></i>
                        {error}
                    </div>
                ) : ""}



                <h1>IPTV PREMIUM TV</h1>
                <div className="input">
                    <div className="icono">
                        <i className="fas fa-user"></i>
                    </div>
                    <input type="text" name="name" onChange={e => setName(e.target.value)} placeholder="Nombre usuario" />

                </div>
                <div className="input">

                    <div className="icono">
                        <i className="fas fa-lock"></i>
                    </div>

                    <input type="password" onChange={e => setPassword(e.target.value)} placeholder="Contraseña" />

                </div>

                <div className="buttom">
                    <button type="submit">Iniciar sesión</button>
                </div>


                <div className="opcion_registrarse">
                    ¿Primera vez en IPTV PREMIUM TV? <a href="/">PREBALO GRATIS POR 1 DÍA</a>
                </div>

            </form>

        </div>
    )
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
};