import React from 'react'
import  "./SinResultado.css"
const SinResultado = () => {
    return (
        <div className='SinResultado'>
    
            <h1>Aqui pondre un diseño de error de busqueda</h1>
          
        </div>
    )
}

export default SinResultado
