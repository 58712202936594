import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Header from '../Includes/Header/Header'
import axios from 'axios'
import './Peliculas.css'
import Loader from '../Includes/Loader/Loader';
import SinResultado from '../SinResultado/SinResultado';
import IconoPaginacionLeft from './svg/paginacion-left.svg';
import IconoPaginacionRight from './svg/paginacion-right.svg';


function Peliculas() {

    const [searchParams] = useSearchParams();

    /*Parametros*/
    const categoria_param = searchParams.get('categoria');
    const page_param = searchParams.get('page');

    /*Use State*/
    const [categoria, setCategoria] = useState();
    const [page, setPage] = useState();
    const [existe_paginacion, setExistePaginacion] = useState();

    useEffect(() => {
        /*Validacion de categoria peliculas*/
        if (categoria_param) {

            const categorias = [
                {
                    name: 'accion',
                    id: 0,
                },
                {
                    name: 'animacion',
                    id: 1,
                },
                {
                    name: 'aventura',
                    id: 2,
                },
                {
                    name: 'belico-guerra',
                    id: 3,
                },
                {
                    name: 'biografia',
                    id: 4,
                },
                {
                    name: 'ciencia-ficcion',
                    id: 5,
                },
                {
                    name: 'comedia',
                    id: 6,
                },
                {
                    name: 'crimen',
                    id: 7,
                },
                {
                    name: 'documentales',
                    id: 8,
                },
                {
                    name: 'drama',
                    id: 9,
                },
                {
                    name: 'familiar',
                    id: 10,
                },
                {
                    name: 'fantasia',
                    id: 11,
                },
                {
                    name: 'misterio',
                    id: 12,
                },
                {
                    name: 'musical',
                    id: 13,
                },
                {
                    name: 'romance',
                    id: 14,
                },
                {
                    name: 'terror',
                    id: 15,
                },
                {
                    name: 'thriller',
                    id: 16,
                },

            ];

            if (categorias.filter(categoria => categoria.name === categoria_param).length > 0) {
                setCategoria(categorias.filter(categoria => categoria.name === categoria_param)[0].id)
            } else {
                setCategoria('NULL');
            }



        } else {
            setCategoria(0);
        }

        /*Validacion de page peliculas*/
        if (page_param) {
            setPage(page_param)
        } else {
            setPage(1);
        }
    }, [categoria_param, page_param]);

    const [peliculas, setPeliculas] = useState("");

    useEffect(() => {
        async function peliculas() {
            await axios({
                method: "POST",
                url: "https://cvn2.iptvpremium.io/category",
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',

                },
                data: {
                    "query": categoria,
                    "page": page
                },
            })
                .then(res => {
                    setPeliculas(res.data);
                })

                .catch(err => {
                    //   console.log("error in request", err);
                });
        }
        peliculas();

        async function VerificarExistenciaPaginacion() {
            await axios({
                method: "POST",
                url: "https://cvn2.iptvpremium.io/category",
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',

                },
                data: {
                    "query": categoria,
                    "page": Number(page) + 1
                },
            })
                .then(res => {

                    if (res.data.length > 0) {
                        setExistePaginacion(true);
                    } else {
                        setExistePaginacion(false);
                    }
                })

                .catch(err => {
                    //   console.log("error in request", err);
                });
        }
        VerificarExistenciaPaginacion();


    }, [categoria, page]);


    return (
        <>
            <Header />

            <section className="contenedor-size-mediano section">
                <h1 className="titulo">Peliculas {categoria_param ? " - " + categoria_param.replace("-", ' ') : "- Todas"}  </h1>

                <main className="main">
                    <div className="categorias">

                        <a className="categoria" href="/peliculas?categoria=accion">
                            Acción
                        </a>

                        <a className="categoria" href="/peliculas?categoria=animacion">
                            Animación
                        </a>

                        <a className="categoria" href="/peliculas?categoria=aventura">
                            Aventura
                        </a>

                        <a className="categoria" href="/peliculas?categoria=belico-guerra">
                            Bélico guerra
                        </a>
                        <a className="categoria" href="/peliculas?categoria=biografia">
                            Biografía
                        </a>

                        <a className="categoria" href="/peliculas?categoria=ciencia-ficcion">
                            Ciencia ficción
                        </a>

                        <a className="categoria" href="/peliculas?categoria=comedia">
                            Comedia
                        </a>
                        <a className="categoria" href="/peliculas?categoria=crimen">
                            Crimen
                        </a>
                        <a className="categoria" href="/peliculas?categoria=documentales">
                            Documentales
                        </a>
                        <a className="categoria" href="/peliculas?categoria=drama">
                            Drama
                        </a>
                        <a className="categoria" href="/peliculas?categoria=familiar">
                            Familiar
                        </a>
                        <a className="categoria" href="/peliculas?categoria=fantasia">
                            Fantasía
                        </a>
                        <a className="categoria" href="/peliculas?categoria=misterio">
                            Misterio
                        </a>
                        <a className="categoria" href="/peliculas?categoria=musical">
                            Musical
                        </a>
                        <a className="categoria" href="/peliculas?categoria=romance">
                            Romance
                        </a>
                        <a className="categoria" href="/peliculas?categoria=terror">
                            Terror
                        </a>
                        <a className="categoria" href="/peliculas?categoria=thriller">
                            Thriller
                        </a>
                    </div>
                </main>


                {peliculas === "" ?
                    <Loader />
                    :
                    <>
                        {peliculas.length === 0 ?
                            <SinResultado />
                            :
                            <>
                                <div className="caractulas">
                                    {peliculas.map((pelicula) => (
                                        <a href={"pelicula/" + pelicula.id} key={pelicula.id}>
                                            <div className="caractula">

                                                <img src={pelicula.poster} alt="img" />
                                                <div className="duracion">{pelicula.duration}</div>
                                                <div className="titulo_caractula">
                                                    {pelicula.title}
                                                </div>
                                            </div>
                                        </a>
                                    ))}

                                </div>

                                <div className="paginacion">

                                    {page > 1 ?
                                        <a href={categoria_param ? page ? "?categoria=" + categoria_param + "&&page=" + (Number(page) - 1) : "" : page ? "?page=" + (Number(page) - 1) : ""}>
                                            <button>
                                                <img src={IconoPaginacionLeft} alt="Icono Paginacion Left" />
                                            </button>
                                        </a>

                                        : ""}

                                    <div className="numero_pagina">
                                        {page}
                                    </div>

                                    {existe_paginacion ?
                                        <a href={categoria_param ? page ? "?categoria=" + categoria_param + "&&page=" + (Number(page) + 1) : "" : page ? "?page=" + (Number(page) + 1) : ""}>
                                            <button>
                                                <img src={IconoPaginacionRight} alt="Icono Paginacion Right" />
                                            </button>
                                        </a>

                                        : ""}
                                </div>
                            </>
                        }
                    </>
                }
            </section>
        </>
    )
}

export default Peliculas
