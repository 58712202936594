import React from 'react'
import PropTypes from 'prop-types'
import './Live.css'
import { Helmet } from 'react-helmet'
import ReactJWPlayer from 'react-jw-player';


const Live = ({ UrlVideo, FormatoVideo, Slug }) => {



    return (
        <>
            <Helmet>
                <title>Live</title>
            </Helmet>

            <section className='contenedor_live'>

                {FormatoVideo === 1 ?
                    <ReactJWPlayer
                        playerId='my-unique-id'
                        playerScript='https://cdn.jwplayer.com/libraries/7sjHar0d.js'
                        file={UrlVideo}
                    />
                    : ""}


                {FormatoVideo === 4 ?
                    <iframe title="bitmovin" src={"bitmovin.html?slug=" + Slug} className='bitmovin' />
                    : ""}


                {FormatoVideo === 3 ?
                    <iframe title="Futbol en vivo" src={UrlVideo} className='iframe_live' />
                    : ""}

            </section>
        </>
    )
}

Live.propTypes = {
    UrlVideo: PropTypes.string.isRequired,
    FormatoVideo: PropTypes.number.isRequired
}

export default Live
