import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import Header from '../Includes/Header/Header'
import Loader from '../Includes/Loader/Loader';
import SinResultado from '../SinResultado/SinResultado';
import './Busqueda.css'
import IconoPaginacionLeft from './svg/paginacion-left.svg';
import IconoPaginacionRight from './svg/paginacion-right.svg';
import axios from 'axios';

const Busqueda = () => {


    const [searchParams] = useSearchParams();

    /*Parametros*/
    const query_param = searchParams.get('query');
    const page_param = searchParams.get('page');

    /*Use State*/
    const [query, setQuery] = useState();
    const [page, setPage] = useState();
    const [existe_paginacion, setExistePaginacion] = useState();



    useEffect(() => {
        /*Validacion de query resultado*/
        if (query_param) {
            setQuery(query_param);
        } else {
            setQuery("");
        }

        /*Validacion de page resultado*/
        if (page_param) {
            setPage(page_param)
        } else {
            setPage(1);
        }
    },[query_param, page_param]);


    const [resultados, setResultados] = useState("");

    useEffect(() => {
        async function resultados() {
            await axios({
                method: "POST",
                url: "https://cvn2.iptvpremium.io/search",
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',

                },
                data: {
                    "query": query,
                    "page": page
                },
            })
                .then(res => {
                    setResultados(res.data);
                })

                .catch(err => {
                    //   console.log("error in request", err);
                });
        }
        resultados();

        async function VerificarExistenciaPaginacion() {
            await axios({
                method: "POST",
                url: "https://cvn2.iptvpremium.io/search",
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',

                },
                data: {
                    "query": query,
                    "page": Number(page) + 1
                },
            })
                .then(res => {

                    if (res.data.length > 0) {
                        setExistePaginacion(true);
                    } else {
                        setExistePaginacion(false);
                    }
                })

                .catch(err => {
                    //   console.log("error in request", err);
                });
        }
        VerificarExistenciaPaginacion();


    }, [query, page]);



    return (
        <>

            <Header />

            <form className="buscador contenedor-size-mediano">

                <input name='query' type="text" placeholder="Buscar peliculas y series" />

                <button type="submit">
                    <i className="fas fa-search"></i>
                </button>

            </form>

            <div className="contenedor-size-mediano">


                {resultados === "" ?
                    <Loader />
                    :
                    <>
                        {resultados.length === 0 ?
                            <SinResultado />
                            :
                            <>

                                <div className="caractulas">
                                    {resultados.map((resultado) => (
                                        <a href={resultado.tipo === "serie" ? resultado.id : "pelicula/" + resultado.id} key={resultado.id}>
                                            <div className="caractula">
                                                <div className={resultado.tipo === "serie" ? "type type_serie" : "type type_pelicula"} >{resultado.tipo}</div>
                                                <img src={resultado.poster} alt="img" />
                                                {resultado.tipo === "pelicula" ?
                                                    <div className="duracion">{resultado.duration}</div>
                                                    :
                                                    ""
                                                }
                                                <div className="titulo_caractula">
                                                    {resultado.title}
                                                </div>
                                            </div>
                                        </a>
                                    ))}

                                </div>

                                <div className="paginacion">

                                    {page > 1 ?
                                        <a href={query_param ? page ? "?query=" + query_param + "&&page=" + (Number(page) - 1) : "" : page ? "?page=" + (Number(page) - 1) : ""}>
                                            <button>
                                                <img src={IconoPaginacionLeft} alt="Icono Paginacion Left" />
                                            </button>
                                        </a>

                                        :
                                        ""
                                    }

                                    <div className="numero_pagina">
                                        {page}
                                    </div>

                                    {existe_paginacion ?
                                        <a href={query_param ? page ? "?query=" + query_param + "&&page=" + (Number(page) + 1) : "" : page ? "?page=" + (Number(page) + 1) : ""}>
                                            <button>
                                                <img src={IconoPaginacionRight} alt="Icono Paginacion Right" />
                                            </button>
                                        </a>


                                        : ""}


                                </div>

                            </>

                        }





                    </>

                }


            </div>

        </>
    )
}

export default Busqueda
