import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Header from '../Includes/Header/Header'
import Error404 from '../Error404/Error404'
import axios from 'axios'
import './Serie.css'
import Loader from '../Includes/Loader/Loader';
import { Helmet } from 'react-helmet';


const Serie = () => {

    const [mostrarEpisodios, SetmostrarEpisodios] = useState(true);
    const [mostrarDetalles, SetMostrarDetalles] = useState(false);

    const Fun_mostrarEpisodios = () => {
        SetmostrarEpisodios(true);
        SetMostrarDetalles(false);
    }

    const Fun_mostrarDetalles = () => {
        SetmostrarEpisodios(false);
        SetMostrarDetalles(true);
    }

    const { nombre } = useParams();

    const [serie, setSerie] = useState("");

    useEffect(() => {

        async function serie() {
            await axios({
                method: "POST",
                url: "https://cvn2.iptvpremium.io/detail",
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest'
                },
                data: {
                    "id": "serie/" + nombre
                },
            })
                .then(res => {
                    setSerie(res.data);


                })

                .catch(err => {
                    setSerie(null);
                });
        }

        serie();


    }, [nombre]);

    return (

        <>
            <Helmet>
                <title>{serie.title}</title>
            </Helmet>

            {serie === null ?
                <Error404 />
                :
                <>
                    <Header />
                    {serie === "" ?
                        <Loader />
                        :
                        <section className="section_detalles contenedor-size-full">

                            <div className="degradado-left">

                            </div>
                            <img id="imagen_portada_detalles_pelicula" src={serie.background} alt="" />

                            <div className="degradado-bottom"></div>

                            <div className="contenidos">

                                <div className="informacion-video">
                                    <div className="caractula_pelicula"
                                        style={{ backgroundImage: "url(" + serie.poster + ")" }}>
                                    </div>
                                    <div>
                                        { /*
                                        <div className="btns">
                                            <a href={"/video/" + serie.id}>

                                                <button className="btn_ver_mas"><i className="fas fa-play"></i> Ver ahora </button>
                                            </a>
                                        </div>*/ }
                                        
                                        <h2 className="titulo" >{serie.title}</h2>
                                        <div className="descripcion" >
                                            {serie.sypnosis}
                                        </div>
                                    </div>

                                </div>

                                <nav className="navegacion-section">

                                    <ul>
                                        <li onClick={Fun_mostrarEpisodios}>Episodios</li>
                                        <li onClick={Fun_mostrarDetalles}>Detalles</li>
                                    </ul>

                                </nav>

                                <div id={mostrarEpisodios ? "" : "ocultar"}>
                                    {serie.episodes.map((episodes, index) => (
                                        <div key={index}>
                                            <h2 className="titulo_temporada">Tempodrada {++index} </h2>
                                            <div className="wrapper" id="sugerencias">
                                                {episodes.episodes.map((episode) => (
                                                    <a href={"/video/" + episode.id} key={episode.id}>

                                                        <div className="item">
                                                            <img alt={episode.id} src={episode.preview} />
                                                            {episode.id.replace("episodio/", "")}
                                                        </div>
                                                    </a>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                {mostrarDetalles ?

                                    <div className="detalles" id="detalles">
                                        <div className="descripcion">
                                            <h3 className='titulo_detalle'>{serie.title}</h3>
                                            <p className="text-detalle">{serie.sypnosis}</p>

                                        </div>
                                        <div className="informaciones">


                                            {
                                                serie?.director ?
                                                    <div className="informacion">
                                                        <label>Director: </label>
                                                        {serie.director.map((director, index) => (
                                                            <span key={index}>{director}, </span>

                                                        ))}

                                                    </div>
                                                    : ""}



                                            {serie?.duration ?
                                                <div className="informacion">
                                                    <label>Duration: </label> <span>{serie.duration}</span>
                                                </div>

                                                : ""}

                                            {
                                                serie?.genres ?
                                                    <div className="informacion">
                                                        <label>Generos: </label>
                                                        {serie.genres.map((genero, index) => (
                                                            <span key={index}>{genero.genre}, </span>

                                                        ))}

                                                    </div>
                                                    : ""}

                                            {serie?.rating ?
                                                <div className="informacion">
                                                    <label>rating: </label> <span>{serie.rating}</span>
                                                </div>

                                                : ""}


                                            {serie?.year ?
                                                <div className="informacion">
                                                    <label>Año: </label> <span>{serie.year}</span>
                                                </div>

                                                : ""}

                                        </div>
                                    </div>

                                    : ""}
                            </div>

                        </section>

                    }
                </>
            }

            <div id='utilizar_imagen_portada'></div>
            <div id='utilizar_slider_peliculas'></div>
        </>

    )
}

export default Serie
