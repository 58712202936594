import React, { useEffect, useState } from 'react'

import IconoPelicula from './iconos/icono-pelicula.svg';
import IconoInicio from './iconos/icono-Inicio.svg';
import IconoBúsqueda from './iconos/icono-Busqueda.svg';
import AbrirMenu from './iconos/icono-abrir-Menu.svg';
import axios from 'axios';


const Header = () => {

    const cerrarSession = () => {
        sessionStorage.removeItem('token');
        window.location.href = "/";
    }

    const [nombreUser, setNombreUser] = useState("");


    useEffect(() => {
        async function GetDatasUser() {
            await axios({
                method: "GET",
                url: "https://dev.iptvpremium.io/api/auth/detalles-user",
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': 'Bearer ' + JSON.parse(sessionStorage.getItem('token'))?.token
                },
            })
                .then(res => {
                    setNombreUser(res.data.data.usuario.name);
                })

                .catch(err => {
                    cerrarSession();
                });
        }
        GetDatasUser();
    }, [])


    return (

        <header id="header-principal" className="header-principal contenedor-size-full">

            <nav className="navegacion-header">

                <div className="logo-header">
                    <a href="/">
                        IPTV PREMIUM TV
                    </a>
                </div>
                <ul>
                    <li>
                        <a href="/">
                            <img src={IconoInicio} alt="Icono inicio" />
                            <span className="texto_navegacion">Inicio</span>
                        </a>
                    </li>

                    <li>
                        <a href="/busqueda">
                            <img src={IconoBúsqueda} alt="Icono búsqueda" />
                            <span className="texto_navegacion">Búsqueda</span>
                        </a>
                    </li>

                    <li className="solo-movil">
                        <button className="btn_abrir_lists_nevegacion" id="btn_abrir_lists_nevegacion">
                            <img src={AbrirMenu} alt="Icono menu" />
                        </button>
                    </li>

                    <div className="lists" id="lists">

                        <li>
                            <a href="/futbol-en-vivo">
                                <i className="fas fa-futbol"></i>
                                Fútbol en vivo
                            </a>
                        </li>

                        <li>
                            <a href="/peliculas">
                                <img src={IconoPelicula} alt="Icono pelicuala" />
                                Películas
                            </a>
                        </li>

                        <li>
                            <a href="/series">
                                <img src={IconoPelicula} alt="Icono pelicula" />
                                Series
                            </a>
                        </li>

                    </div>

                </ul>

                <button id="abrir_opciones_perfil" className="abrir_opciones_perfil">
                    <div className="perfil-header">

                        <span className="nombre-usuario">{nombreUser}</span>

                        <div className="foto-usuario">{nombreUser?.charAt(0).toUpperCase()}</div>

                        <div className="opciones_perfil_header" id="opciones_perfil_header">
                            <ul>
                                <li><a href="/">Perfil</a></li>
                                <li onClick={() => cerrarSession()}>Cerrar sesion</li>
                            </ul>
                        </div>
                    </div>
                </button>
            </nav>
        </header>
    )
}

export default Header
