import React from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from '../Login/Login';
import useToken from './useToken';
import Error404 from '../Error404/Error404';
import Inicio from '../Inicio/Inicio';
import Peliculas from '../Peliculas/Peliculas';
import Pelicula from '../Pelicula/Pelicula';
import Series from '../Series/Series';
import Serie from '../Serie/Serie';
import Video from '../Video/Video';
import Busqueda from '../Busqueda/Busqueda';
import FutbolEnVivo from '../FutbolEnVivo/FutbolEnVivo';


function App() {

  const { token, setToken } = useToken();

  if (!token) {
    return <Login setToken={setToken} />
  }

  return (

    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Inicio />} />
        <Route path="/busqueda" element={<Busqueda />} />
        <Route path="/peliculas" element={<Peliculas />} />
        <Route path="/pelicula/:id/:nombre" element={<Pelicula />} />
        <Route path="/Series" element={<Series />} />
        <Route path="/Serie/:nombre" element={<Serie />} />
        <Route path="/futbol-en-vivo" element={<FutbolEnVivo />} />
        <Route path="/video/:id/:nombre" element={<Video />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </BrowserRouter>

  );
}

export default App;