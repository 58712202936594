import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Header from '../Includes/Header/Header'
import Error404 from '../Error404/Error404'
import axios from 'axios'
import './Pelicula.css'
import Loader from '../Includes/Loader/Loader';
import { Helmet } from 'react-helmet';


const Pelicula = () => {

    const { id, nombre } = useParams();

    const [pelicula, setPelicula] = useState("");

    const [mostrarSugerencias, SetmostrarSugerencias] = useState(true);
    const [mostrarDetalles, SetMostrarDetalles] = useState(false);

    const Fun_mostrarSugentencias = () => {
        SetmostrarSugerencias(true);
        SetMostrarDetalles(false);
    }

    const Fun_mostrarDetalles = () => {
        SetmostrarSugerencias(false);
        SetMostrarDetalles(true);
    }

    useEffect(() => {

        async function pelicula() {
            await axios({
                method: "POST",
                url: "https://cvn2.iptvpremium.io/detail",
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest'
                },
                data: {
                    "id": id + "/" + nombre
                },
            })
                .then(res => {
                    setPelicula(res.data);
                })

                .catch(err => {
                    setPelicula(null);
                });
        }


        pelicula();
    }, [id, nombre]);

    return (

        <>
            <Helmet>
                <title>{pelicula.title}</title>
            </Helmet>

            {pelicula === null ?
                <Error404 />
                :
                <>

                    <Header />
                    {pelicula === "" ?
                        <Loader />
                        :
                        <section className="section_detalles contenedor-size-full">

                            <div className="degradado-left">

                            </div>
                            <img id="imagen_portada_detalles_pelicula" src={pelicula.background} alt="" />

                            <div className="degradado-bottom"></div>

                            <div className="contenidos">

                                <div className="informacion-video">
                                    <div className="caractula_pelicula"
                                        style={{ backgroundImage: "url(" + pelicula.poster + ")" }}>
                                    </div>
                                    <div>
                                        <div className="btns">
                                            <a href={"/video/" + pelicula.id}>

                                                <button className="btn_ver_mas"><i className="fas fa-play"></i> Ver ahora </button>
                                            </a>
                                        </div>
                                        <h2 className="titulo" >{pelicula.title}</h2>
                                        <div className="descripcion" >
                                            {pelicula.sypnosis}
                                        </div>
                                    </div>

                                </div>

                                <nav className="navegacion-section">
                                    <ul>
                                        <li onClick={Fun_mostrarSugentencias}>Sugenrencias</li>
                                        <li onClick={Fun_mostrarDetalles}>Detalles</li>
                                    </ul>
                                </nav>

                                <div
                                    className="wrapper"
                                    id={mostrarSugerencias ? "" : "ocultar"}>

                                    <a href='/pelicula/52852/south-park-post-covid-the-return-of-covid'>
                                        <div className="item">
                                            <img alt="" src="https://cuevana3.io/wp-content/uploads/2021/12/south-park-post-covid-el-retorno-del-covid-52852-poster-200x300.jpg" />
                                            South Park – Post Covid: El Retorno del Covid
                                        </div>
                                    </a>

                                    <a href='/pelicula/52848/mass'>
                                        <div className="item">
                                            <img alt="" src="https://cuevana3.io/wp-content/uploads/2021/12/mass-52848-poster-202x300.jpg" />
                                            Mass
                                        </div>
                                    </a>


                                    <a href='/pelicula/52844/national-champions'>
                                        <div className="item">
                                            <img alt="" src="https://cuevana3.io/wp-content/uploads/2021/12/national-champions-52844-poster-200x300.jpg" />
                                            National Champions
                                        </div>
                                    </a>


                                    <a href='/pelicula/52840/the-spore'>
                                        <div className="item">
                                            <img alt="" src="https://cuevana3.io/wp-content/uploads/2021/12/the-spore-52840-poster-200x300.jpg" />
                                            The Spore
                                        </div>
                                    </a>

                                    <a href='/pelicula/52836/guzen-to-sozo'>
                                        <div className="item">
                                            <img alt="" src="https://cuevana3.io/wp-content/uploads/2021/12/la-ruleta-de-la-fortuna-y-la-fantasia-52836-poster-210x300.jpg" />
                                            La ruleta de la fortuna y la fantasía
                                        </div>
                                    </a>

                                    <a href='/pelicula/51548/ghostbusters-afterlife'>
                                        <div className="item">
                                            <img alt="" src="https://cuevana3.io/wp-content/uploads/2021/11/cazafantasmas-mas-alla-51548-poster-203x300.jpg" />
                                            Cazafantasmas: Más allá
                                        </div>
                                    </a>

                                    <a href='/pelicula/52832/dean-martin-king-of-cool'>
                                        <div className="item">
                                            <img alt="" src="https://cuevana3.io/wp-content/uploads/2021/12/dean-martin-king-of-cool-52832-poster-200x300.jpg" />
                                            Dean Martin: King of Cool
                                        </div>
                                    </a>

                                    <a href='/pelicula/52827/the-trick'>
                                        <div className="item">
                                            <img alt="" src="https://cuevana3.io/wp-content/uploads/2021/12/the-trick-52827-poster-200x300.jpg" />
                                            The Trick
                                        </div>
                                    </a>
                                    <a href='/pelicula/52824/hell-of-a-night'>
                                        <div className="item">
                                            <img alt="" src="https://cuevana3.io/wp-content/uploads/2021/12/hell-of-a-night-52824-poster-200x300.jpg" />
                                            Hell of a Night
                                        </div>
                                    </a>
                                    <a href='/pelicula/52819/lulli'>
                                        <div className="item">
                                            <img alt="" src="https://cuevana3.io/wp-content/uploads/2021/12/lulli-52819-poster-204x300.jpg" />
                                            Lulli
                                        </div>
                                    </a>

                                </div>

                                {mostrarDetalles ?

                                    <div className="detalles" id="detalles">
                                        <div className="descripcion">
                                            <h3 className='titulo_detalle'>{pelicula.title}</h3>
                                            <p className="text-detalle">{pelicula.sypnosis}</p>

                                        </div>
                                        <div className="informaciones">


                                            {
                                                pelicula?.director ?
                                                    <div className="informacion">
                                                        <label>Director: </label>
                                                        {pelicula.director.map((director, index) => (
                                                            <span key={index}>{director}, </span>

                                                        ))}

                                                    </div>
                                                    : ""}



                                            {pelicula?.duration ?
                                                <div className="informacion">
                                                    <label>Duration: </label> <span>{pelicula.duration}</span>
                                                </div>

                                                : ""}

                                            {
                                                pelicula?.genres ?
                                                    <div className="informacion">
                                                        <label>Generos: </label>
                                                        {pelicula.genres.map((genero, index) => (
                                                            <span key={index}>{genero.genre}, </span>

                                                        ))}

                                                    </div>
                                                    : ""}



                                            {pelicula?.rating ?
                                                <div className="informacion">
                                                    <label>rating: </label> <span>{pelicula.rating}</span>
                                                </div>

                                                : ""}


                                            {pelicula?.year ?
                                                <div className="informacion">
                                                    <label>Año: </label> <span>{pelicula.year}</span>
                                                </div>

                                                : ""}

                                            {pelicula?.links ?
                                                <div className="informacion">
                                                    <label>Idiomas disponibles: </label>

                                                    {pelicula.links.espanol.length > 0 ?
                                                        <span>Español, </span> : ""}
                                                    {pelicula.links.latino.length > 0 ?
                                                        <span>Latino, </span> : ""}
                                                    {pelicula.links.sub.length > 0 ?
                                                        <span>Ingles </span> : ""}
                                                </div>
                                                : ""}
                                        </div>
                                    </div>

                                    : ""}
                            </div>

                        </section>
                    }
                </>
            }

            <div id='utilizar_imagen_portada'></div>
            <div id='utilizar_slider_peliculas'></div>

        </>

    )
}

export default Pelicula
