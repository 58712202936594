import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Header from '../Includes/Header/Header'
import axios from 'axios'
import './Series.css'
import Loader from '../Includes/Loader/Loader';
import SinResultado from '../SinResultado/SinResultado';
import IconoPaginacionLeft from './svg/paginacion-left.svg';
import IconoPaginacionRight from './svg/paginacion-right.svg';

function Series() {

    const [searchParams] = useSearchParams();

    /*Parametros*/
    const type_param = searchParams.get('type');
    const page_param = searchParams.get('page');

    /*Use State*/
    const [type, setType] = useState();
    const [page, setPage] = useState();
    const [existe_paginacion, setExistePaginacion] = useState();

    useEffect(() => {
        /*Validacion de type Series*/
        if (type_param) {

            const types = [
                {
                    name: 'ultimas',
                    id: 0,
                },
                {
                    name: 'estrenos',
                    id: 1,
                },
                {
                    name: 'ranking',
                    id: 2,
                },
                {
                    name: 'mas-vista',
                    id: 3,
                },
            ];

            if (types.filter(student => student.name === type_param).length > 0) {
                setType(types.filter(student => student.name === type_param)[0].id)
            } else {
                setType('NULL');
            }

        } else {
            setType(1);
        }

        /*Validacion de page Series*/
        if (page_param) {
            setPage(page_param)
        } else {
            setPage(1);
        }
    }, [type_param, page_param]);


    const [Series, setSeries] = useState("");

    useEffect(() => {
        async function Series() {
            await axios({
                method: "POST",
                url: "https://cvn2.iptvpremium.io/seriespaginate",
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',

                },
                data: {
                    "type": type,
                    "page": page
                },
            })
                .then(res => {
                    setSeries(res.data);
                })

                .catch(err => {
                    //   console.log("error in request", err);
                });
        }
        Series();

        async function VerificarExistenciaPaginacion() {
            await axios({
                method: "POST",
                url: "https://cvn2.iptvpremium.io/seriespaginate",
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',

                },
                data: {
                    "type": type,
                    "page": Number(page) + 1
                },
            })
                .then(res => {

                    if (res.data.length > 0) {
                        setExistePaginacion(true);
                    } else {
                        setExistePaginacion(false);
                    }
                })

                .catch(err => {
                    //   console.log("error in request", err);
                });
        }
        VerificarExistenciaPaginacion();


    }, [type, page]);

    return (
        <>
            <Header />

            <section className="contenedor-size-mediano section">
                <h1 className="titulo">Series  {type_param ? " - " + type_param.replace("-", ' ') : "- ultimas"}  </h1>

                <main className="main">
                    <div className="categorias">
                        <a className="categoria" href="/series?type=ultimas">
                            Últimas
                        </a>
                        <a className="categoria" href="/series?type=estrenos">
                            Estrenos
                        </a>

                        <a className="categoria" href="/series?type=ranking">
                            Ranking
                        </a>
                        <a className="categoria" href="/series?type=mas-vista">
                            Mas vistas
                        </a>

                    </div>
                </main>


                {Series === "" ?
                    <Loader />
                    :
                    <>
                        {Series.length === 0 ?
                            <SinResultado />
                            :
                            <>

                                <div className="caractulas">
                                    {Series.map((pelicula) => (
                                        <a href={pelicula.id} key={pelicula.id}>
                                            <div className="caractula">

                                                <img src={pelicula.poster} alt="img" />

                                                <div className="titulo_caractula">
                                                    {pelicula.title}
                                                </div>
                                            </div>
                                        </a>
                                    ))}

                                </div>

                                <div className="paginacion">

                                    {page > 1 ?
                                        <a href={type_param ? page ? "?type=" + type_param + "&&page=" + (Number(page) - 1) : "" : page ? "?page=" + (Number(page) - 1) : ""}>
                                            <button>
                                                <img src={IconoPaginacionLeft} alt="Icono Paginacion Left" />
                                            </button>
                                        </a>

                                        :
                                        ""
                                    }

                                    <div className="numero_pagina">
                                        {page}
                                    </div>

                                    {existe_paginacion ?
                                        <a href={type_param ? page ? "?type=" + type_param + "&&page=" + (Number(page) + 1) : "" : page ? "?page=" + (Number(page) + 1) : ""}>
                                            <button>
                                                <img src={IconoPaginacionRight} alt="Icono Paginacion Right" />
                                            </button>
                                        </a>


                                        : ""}


                                </div>

                            </>

                        }

                    </>

                }
            </section>

        </>
    )
}

export default Series
