import React from 'react'
import Header from '../Includes/Header/Header'
import './Error404.css'

const Error404 = () => {
    return (
        <>
            <Header />
            <section class="contenedor_error">
                <span class="error">404</span>
                <h1>No encontramos la página que estás buscando.</h1>
                <p>Haz clic en el siguiente botón para ir a la página de inicio</p>
                <a href="/">
                    <button>Volver a inicio</button>
                </a>

            </section>

        </>
    )
}

export default Error404
